import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

// Import Tailwind CSS
import './assets/main.css'

// Create app instance
const app = createApp(App)

// Initialize Pinia
const pinia = createPinia()
app.use(pinia)

// Initialize stores
import { useAuthStore } from './stores/auth'
import { useMaintenanceModeStore } from './stores/maintenanceMode'

const authStore = useAuthStore()
const maintenanceModeStore = useMaintenanceModeStore()

// Initialize auth first
await authStore.init()

// Then initialize router
app.use(router)

// Mount app
app.mount('#app')

console.log('App mounted')

import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useMaintenanceModeStore = defineStore('maintenanceMode', () => {
  // Initialize refs with default values
  const isMaintenanceMode = ref(false);
  const maintenanceMessage = ref('The site is currently undergoing scheduled maintenance. Please check back soon.');
  const isLoading = ref(false);

  // Load state from localStorage
  const loadState = () => {
    try {
      const savedState = localStorage.getItem('maintenanceMode');
      if (savedState) {
        const { enabled, message } = JSON.parse(savedState);
        isMaintenanceMode.value = enabled;
        if (message) {
          maintenanceMessage.value = message;
        }
      }
    } catch (err) {
      console.error('Error loading maintenance mode state:', err);
    }
  };

  // Save state to localStorage
  const saveState = () => {
    try {
      const state = {
        enabled: isMaintenanceMode.value,
        message: maintenanceMessage.value,
        updatedAt: new Date().toISOString()
      };
      localStorage.setItem('maintenanceMode', JSON.stringify(state));
      console.log('Saved maintenance mode state:', state);
    } catch (err) {
      console.error('Error saving maintenance mode state:', err);
    }
  };

  // Toggle maintenance mode
  const toggleMaintenanceMode = () => {
    try {
      isMaintenanceMode.value = !isMaintenanceMode.value;
      saveState();
      console.log('Maintenance mode toggled:', isMaintenanceMode.value);
      return true;
    } catch (err) {
      console.error('Error toggling maintenance mode:', err);
      return false;
    }
  };

  // Set maintenance message
  const setMaintenanceMessage = (message) => {
    try {
      if (!message || message.trim() === '') return false;
      maintenanceMessage.value = message.trim();
      saveState();
      return true;
    } catch (err) {
      console.error('Error setting maintenance message:', err);
      return false;
    }
  };

  // Watch for changes and save to localStorage
  watch(
    [isMaintenanceMode, maintenanceMessage],
    () => {
      saveState();
    },
    { deep: true }
  );

  // Load initial state
  loadState();

  return {
    isMaintenanceMode,
    maintenanceMessage,
    isLoading,
    toggleMaintenanceMode,
    setMaintenanceMessage
  };
});

// Local Storage Service for Content and Settings
import { ref } from 'vue';

// Storage
const contentStorage = ref({});
const settingsStorage = ref({});

// Default content for home page
const defaultHomeContent = {
  'title': '<h1 class="text-4xl font-bold text-gray-900 mb-6">Manuel Rodríguez de Viguri</h1>',
  'subtitle': '<p class="text-xl text-gray-600 mb-8">Software Engineer & Full Stack Developer</p>',
  'card1_title': '<h3 class="font-semibold text-gray-900 mb-2">Software Development</h3>',
  'card1_content': '<p class="text-gray-600">Mainframe, Java, Python</p>',
  'card2_title': '<h3 class="font-semibold text-gray-900 mb-2">Emerging Tech</h3>',
  'card2_content': '<p class="text-gray-600">AI, Machine Learning, Blockchain</p>',
  'card3_title': '<h3 class="font-semibold text-gray-900 mb-2">Web Development</h3>',
  'card3_content': '<p class="text-gray-600">Vue.js, React, Node.js</p>'
};

// Initialize from localStorage
const initStorage = () => {
  console.log('Initializing local storage...');
  // Initialize content
  const storedContent = localStorage.getItem('site_content');
  if (storedContent) {
    try {
      contentStorage.value = JSON.parse(storedContent);
      console.log('Parsed content from localStorage:', contentStorage.value);
    } catch (error) {
      console.error('Error parsing stored content:', error);
      localStorage.removeItem('site_content');
      contentStorage.value = {};
    }
  } else {
    contentStorage.value = {};
  }

  // Ensure home content exists
  if (!contentStorage.value.home) {
    console.log('Home content missing, adding default content');
    contentStorage.value.home = defaultHomeContent;
    saveToLocalStorage('content');
  }

  
  // Initialize settings
  const storedSettings = localStorage.getItem('site_settings');
  if (storedSettings) {
    try {
      settingsStorage.value = JSON.parse(storedSettings);
    } catch (error) {
      console.error('Error parsing stored settings:', error);
      localStorage.removeItem('site_settings');
    }
  }
};

// Save to localStorage
const saveToLocalStorage = (type) => {
  try {
    if (type === 'content') {
      localStorage.setItem('site_content', JSON.stringify(contentStorage.value));
      console.log('Saved content to localStorage:', contentStorage.value);
    } else if (type === 'settings') {
      localStorage.setItem('site_settings', JSON.stringify(settingsStorage.value));
      console.log('Saved settings to localStorage:', settingsStorage.value);
    }
  } catch (err) {
    console.error(`Error saving ${type} to localStorage:`, err);
  }
};

// Get content for a view
export const getContent = async (viewName) => {
  console.log(`Getting content for ${viewName}`);
  if (!contentStorage.value[viewName]) {
    contentStorage.value[viewName] = {};
  }
  return Promise.resolve(contentStorage.value[viewName]);
};

// Save content for a view
export const saveContent = async (viewName, data) => {
  console.log(`Saving content for ${viewName}:`, data);
  
  try {
    if (!contentStorage.value[viewName]) {
      contentStorage.value[viewName] = {};
    }
    
    // Merge with existing data
    contentStorage.value[viewName] = {
      ...contentStorage.value[viewName],
      ...data,
      updatedAt: new Date().toISOString()
    };
    
    console.log(`Updated content for ${viewName}:`, contentStorage.value[viewName]);
    
    // Save to localStorage
    saveToLocalStorage('content');
    return true;
  } catch (err) {
    console.error(`Error saving content for ${viewName}:`, err);
    return false;
  }
};

// Collection, doc, getDoc, and setDoc functions for compatibility
export const collection = (_, collectionName) => collectionName;

export const doc = (_, collectionName, docName) => ({
  collectionName,
  docName
});

export const getDoc = async (docRef) => {
  const { collectionName, docName } = docRef;
  
  if (collectionName === 'content') {
    if (!contentStorage.value[docName]) {
      return {
        exists: () => false,
        data: () => ({})
      };
    }
    
    return {
      exists: () => true,
      data: () => contentStorage.value[docName]
    };
  } else if (collectionName === 'settings') {
    if (!settingsStorage.value[docName]) {
      return {
        exists: () => false,
        data: () => ({})
      };
    }
    
    return {
      exists: () => true,
      data: () => settingsStorage.value[docName]
    };
  }
  
  return {
    exists: () => false,
    data: () => ({})
  };
};

export const setDoc = async (docRef, data) => {
  const { collectionName, docName } = docRef;
  
  try {
    if (collectionName === 'content') {
      if (!contentStorage.value[docName]) {
        contentStorage.value[docName] = {};
      }
      
      // Keep updatedAt if provided
      const { updatedAt = new Date().toISOString() } = data;
      
      // Merge with existing data
      contentStorage.value[docName] = {
        ...contentStorage.value[docName],
        ...data,
        updatedAt
      };
      
      // Save to localStorage
      saveToLocalStorage('content');
    } else if (collectionName === 'settings') {
      if (!settingsStorage.value[docName]) {
        settingsStorage.value[docName] = {};
      }
      
      // Keep updatedAt if provided
      const { updatedAt = new Date().toISOString() } = data;
      
      // Merge with existing data
      settingsStorage.value[docName] = {
        ...settingsStorage.value[docName],
        ...data,
        updatedAt
      };
      
      // Save to localStorage
      saveToLocalStorage('settings');
    }
    
    return Promise.resolve();
  } catch (err) {
    console.error(`Error setting document ${collectionName}/${docName}:`, err);
    return Promise.reject(err);
  }
};

// Timestamp function for compatibility
export const serverTimestamp = () => new Date().toISOString();

// Database object for compatibility
export const db = {};

// Initialize storage on load
initStorage();

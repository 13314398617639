import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { 
  auth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateCredentials,
  initAuth as initLocalAuth
} from '../services';

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const isLoading = ref(true);
  const error = ref(null);

  // Computed properties
  const isAuthenticated = computed(() => !!user.value);
  const isAdmin = computed(() => {
    if (!user.value) return false;
    return user.value.isAdmin === true;
  });

  // Initialize auth state
  const init = async () => {
    isLoading.value = true;
    error.value = null;
    
    try {
      // Initialize local auth service
      initLocalAuth();
      
      // Set up auth state change listener
      const unsubscribe = onAuthStateChanged((userData) => {
        console.log('Auth state changed:', userData);
        user.value = userData;
        isLoading.value = false;
      });
      
      return unsubscribe;
    } catch (err) {
      console.error('Error initializing auth:', err);
      error.value = err.message;
      isLoading.value = false;
      throw err;
    }
  };

  // Set user directly (for testing)
  const setUser = async (userData) => {
    user.value = userData;
  };

  // Login with Email and Password
  const loginWithEmailPassword = async (email, password) => {
    isLoading.value = true;
    error.value = null;
    
    try {
      const result = await signInWithEmailAndPassword(email, password);
      
      if (!result || !result.user) {
        throw new Error('Authentication failed');
      }
      
      // Set the user from the result
      user.value = result.user;
      return result;
    } catch (err) {
      error.value = 'Invalid email or password';
      throw { code: 'auth/invalid-credential', message: error.value };
    } finally {
      isLoading.value = false;
    }
  };

  // Logout
  const logout = async () => {
    isLoading.value = true;
    error.value = null;
    
    try {
      await signOut();
      user.value = null;
    } catch (err) {
      error.value = err.message;
      console.error('Logout error:', err);
    } finally {
      isLoading.value = false;
    }
  };

  // Validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      throw new Error('Invalid email format');
    }
    return true;
  };

  // Normalize email address
  const normalizeEmail = (email) => {
    return email.trim().toLowerCase();
  };

  // Update user credentials
  const updateUserCredentials = async ({ currentPassword, newEmail, backupEmail, newPassword, rememberPassword }) => {
    isLoading.value = true;
    error.value = null;
    
    try {
      console.log('Updating user credentials:', { currentPassword, newEmail, backupEmail, newPassword, rememberPassword });
      await updateCredentials({ currentPassword, newEmail, backupEmail, newPassword, rememberPassword });
      console.log('Credentials updated successfully');
      return { success: true };
    } catch (err) {
      console.error('Error updating credentials:', err);
      error.value = err.message;
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    user,
    isLoading,
    error,
    isAuthenticated,
    isAdmin,
    init,
    setUser,
    loginWithEmailPassword,
    logout,
    validateEmail,
    normalizeEmail,
    updateUserCredentials
  };
});

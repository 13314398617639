import { defineStore } from 'pinia';
import { ref } from 'vue';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  serverTimestamp 
} from '../services';

export const useContentStore = defineStore('content', () => {
  const content = ref({});
  const isLoading = ref(false);
  const error = ref(null);

  // Initialize content from localStorage
  const initContent = (skipDefaults = false) => {
    console.log('Initializing content store...');
    const storedContent = localStorage.getItem('site_content');
    if (storedContent) {
      try {
        content.value = JSON.parse(storedContent);
        console.log('Loaded content from localStorage:', content.value);
      } catch (err) {
        console.error('Error loading stored content:', err);
        content.value = {};
      }
    } else {
      content.value = {};
      console.log('No stored content found, initialized empty state');
    }

    // Ensure default content exists only if not skipping defaults
    if (!skipDefaults && !content.value.home) {
      content.value.home = {
        title: '<h1 class="text-4xl font-bold text-gray-900 mb-6">Manuel Rodríguez de Viguri</h1>',
        subtitle: '<p class="text-xl text-gray-600 mb-8">Software Engineer & Full Stack Developer</p>',
        card1_title: '<h3 class="font-semibold text-gray-900 mb-2">Software Development</h3>',
        card1_content: '<p class="text-gray-600">Mainframe, Java, Python</p>',
        card2_title: '<h3 class="font-semibold text-gray-900 mb-2">Emerging Tech</h3>',
        card2_content: '<p class="text-gray-600">AI, Machine Learning, Blockchain</p>',
        card3_title: '<h3 class="font-semibold text-gray-900 mb-2">Web Development</h3>',
        card3_content: '<p class="text-gray-600">Vue.js, React, Node.js</p>'
      };
      console.log('Default content initialized:', content.value);
      localStorage.setItem('site_content', JSON.stringify(content.value));
      console.log('Default content saved to localStorage');
    }
  };

  // Get content for a specific view
  const getContent = async (viewName) => {
    console.log(`Getting content for view: ${viewName}`);
    isLoading.value = true;
    error.value = null;

    try {
      // First check localStorage
      if (!content.value[viewName]) {
        console.log(`No content found for ${viewName}, initializing empty object`);
        content.value[viewName] = {};
      }

      console.log(`Current content for ${viewName}:`, content.value[viewName]);

      // Return a copy of the content to avoid direct state mutations
      return { ...content.value[viewName] };
    } catch (err) {
      error.value = err.message;
      console.error(`Error getting ${viewName} content:`, err);
      return {};
    } finally {
      isLoading.value = false;
    }
  };

  // Initialize on store creation with skipDefaults = true for tests
  initContent(true);

  // Save content for a specific view
  const saveContent = async (viewName, data) => {
    isLoading.value = true;
    error.value = null;

    try {
      console.log(`Saving content for ${viewName}:`, data);
      
      // Update local state
      if (!content.value[viewName]) {
        content.value[viewName] = {};
      }
      
      // Merge the new data with existing content
      content.value[viewName] = {
        ...content.value[viewName],
        ...data
      };
      
      console.log(`Updated local content for ${viewName}:`, content.value[viewName]);
      
      // Save to localStorage
      localStorage.setItem('site_content', JSON.stringify(content.value));
      
      return true;
    } catch (err) {
      error.value = err.message;
      console.error(`Error saving ${viewName} content:`, err);
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    content,
    isLoading,
    error,
    getContent,
    saveContent
  };
});

import { createRouter, createWebHistory } from 'vue-router'
import { useMaintenanceModeStore } from '../stores/maintenanceMode'
import { useAuthStore } from '../stores/auth'
import { isSetupNeeded } from '../services/localAuth'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('../views/Setup.vue'),
    beforeEnter: (to, from, next) => {
      // Only allow access if setup is needed
      if (isSetupNeeded()) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/professional',
    name: 'Professional',
    component: () => import('../views/Professional.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/music',
    name: 'Music',
    component: () => import('../views/Music.vue')
  },
  {
    path: '/cultural',
    name: 'Cultural',
    component: () => import('../views/Cultural.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/LoginPage.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../components/ProfileSettings.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard for maintenance mode
router.beforeEach(async (to, from, next) => {
  const maintenanceModeStore = useMaintenanceModeStore()
  const authStore = useAuthStore()

  // Wait for auth to be initialized
  if (authStore.isLoading) {
    await new Promise(resolve => {
      const unwatch = authStore.$subscribe((mutation, state) => {
        if (!state.isLoading) {
          unwatch()
          resolve()
        }
      })
    })
  }

  // Check if route requires authentication
  if (to.meta.requiresAuth) {
    if (!authStore.isAuthenticated) {
      next('/login')
      return
    }

    // Check if route requires admin access
    if (to.meta.requiresAdmin && !authStore.isAdmin) {
      next('/')
      return
    }
  }

  // Always allow access to login page
  if (to.path === '/login') {
    // If user is already logged in as admin and trying to access login page
    if (authStore.isAdmin) {
      next('/')
      return
    }
    next()
    return
  }

  // If in maintenance mode
  if (maintenanceModeStore.isMaintenanceMode) {
    // Allow admin users to access all pages
    if (authStore.isAdmin) {
      next()
      return
    }
    // Redirect non-admin users to login
    next('/login')
    return
  }

  // For all other cases, allow access
  next()
})

export default router

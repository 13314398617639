// Local Authentication Service
import { ref, computed } from 'vue';

// User database - in a real application, this would be stored securely
const defaultUsers = [
  {
    id: '1',
    email: 'admin@example.com',
    password: 'admin123', // In a real app, this would be hashed
    displayName: 'Default Admin',
    isAdmin: true,
    createdAt: '2025-03-21T00:00:00.000Z'
  }
];

// Initialize users array
let users = JSON.parse(localStorage.getItem('users')) || defaultUsers;

// Ensure users are saved to localStorage
if (!localStorage.getItem('users')) {
  localStorage.setItem('users', JSON.stringify(defaultUsers));
  localStorage.setItem('setupComplete', 'true'); // Mark setup as complete for default admin
}

// Auth state
const authState = ref({
  user: null,
  isAuthenticated: false
});

// Check if setup is needed (no users exist)
export const isSetupNeeded = () => {
  // If there's a default admin, setup is not needed
  const users = JSON.parse(localStorage.getItem('users')) || [];
  const hasDefaultAdmin = users.some(user => user.email === 'admin@example.com');
  
  // Setup is needed if there are no users or if the only user is the default admin
  return users.length === 0 || (users.length === 1 && hasDefaultAdmin);
};

// Complete the setup process by creating the admin user
export const completeSetup = async ({ email, password, displayName }) => {
  try {
    // Generate a unique ID
    const id = Date.now().toString(36) + Math.random().toString(36).substr(2);
    
    // Create admin user
    const adminUser = {
      id,
      email: email.toLowerCase(),
      password, // In a real app, this would be hashed
      displayName: displayName || 'Administrator',
      isAdmin: true,
      createdAt: new Date().toISOString()
    };
    
    // Save to storage
    localStorage.setItem('users', JSON.stringify([adminUser]));
    localStorage.setItem('setupComplete', 'true');
    
    // Update auth state
    authState.value = {
      user: adminUser,
      isAuthenticated: true
    };
    
    return adminUser;
  } catch (error) {
    console.error('Setup error:', error);
    throw new Error('Failed to complete setup: ' + error.message);
  }
};

// Sign in with email and password
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    // Normalize email to lowercase for case-insensitive comparison
    const normalizedEmail = email.toLowerCase();
    
    // Find user with matching email and password
    const user = users.find(u => 
      u.email.toLowerCase() === normalizedEmail && 
      u.password === password
    );
    
    if (!user) {
      throw new Error('Invalid email or password');
    }
    
    // Create a copy without the password
    const userWithoutPassword = { ...user };
    delete userWithoutPassword.password;
    
    // Update auth state
    authState.value.user = userWithoutPassword;
    authState.value.isAuthenticated = true;
    
    // Store in localStorage for persistence
    localStorage.setItem('auth_user', JSON.stringify(userWithoutPassword));
    
    return { user: userWithoutPassword };
  } catch (error) {
    throw { code: 'auth/invalid-credential', message: error.message || 'Invalid email or password' };
  }
};

// Sign out
export const signOut = () => {
  authState.value.user = null;
  authState.value.isAuthenticated = false;
  localStorage.removeItem('auth_user');
  return Promise.resolve();
};

// Check if user is already logged in (from localStorage)
export const initAuth = () => {
  const storedUser = localStorage.getItem('auth_user');
  if (storedUser) {
    try {
      const user = JSON.parse(storedUser);
      authState.value.user = user;
      authState.value.isAuthenticated = true;
    } catch (error) {
      console.error('Error parsing stored user:', error);
      localStorage.removeItem('auth_user');
    }
  }
};

// Auth state observer
export const onAuthStateChanged = (callback) => {
  // Initial call with current state
  callback(authState.value.user);
  
  // Return unsubscribe function (no need for polling in local auth)
  return () => {};
};



// Update user credentials
export const updateCredentials = async ({ currentPassword, newEmail, backupEmail, newPassword, rememberPassword }) => {
  try {
    // Find current user
    const currentUser = authState.value.user;
    if (!currentUser) {
      throw new Error('No user is currently logged in');
    }

    // Find user in database
    const userIndex = users.findIndex(u => u.id === currentUser.id);
    if (userIndex === -1) {
      throw new Error('User not found');
    }

    // Verify current password
    if (users[userIndex].password !== currentPassword) {
      throw new Error('Current password is incorrect');
    }

    // Check if new email is already taken by another user
    if (newEmail && newEmail !== users[userIndex].email) {
      const emailExists = users.some(u => 
        u.id !== currentUser.id && 
        (u.email.toLowerCase() === newEmail.toLowerCase() ||
         u.backupEmail.toLowerCase() === newEmail.toLowerCase())
      );
      if (emailExists) {
        throw new Error('Email is already in use');
      }
    }

    // Check if backup email is already taken by another user
    if (backupEmail) {
      const backupEmailExists = users.some(u => 
        u.id !== currentUser.id && 
        (u.email.toLowerCase() === backupEmail.toLowerCase() ||
         u.backupEmail.toLowerCase() === backupEmail.toLowerCase())
      );
      if (backupEmailExists) {
        throw new Error('Backup email is already in use');
      }

      // Ensure backup email is different from primary email
      if (backupEmail.toLowerCase() === (newEmail || users[userIndex].email).toLowerCase()) {
        throw new Error('Backup email must be different from primary email');
      }
    }

    // Update user in database
    const updatedUser = {
      ...users[userIndex],
      email: newEmail || users[userIndex].email,
      backupEmail: backupEmail || users[userIndex].backupEmail,
      password: newPassword || users[userIndex].password,
      rememberPassword: rememberPassword ?? users[userIndex].rememberPassword
    };

    // Update users array
    users[userIndex] = updatedUser;

    // Save to localStorage for persistence
    localStorage.setItem('users', JSON.stringify(users));

    // If remember password is enabled, store credentials securely
    if (updatedUser.rememberPassword) {
      localStorage.setItem('remembered_credentials', JSON.stringify({
        userId: updatedUser.id,
        email: updatedUser.email,
        password: updatedUser.password // In production, use encryption
      }));
    } else {
      localStorage.removeItem('remembered_credentials');
    }

    // Return success
    return { success: true };
  } catch (error) {
    throw { code: 'auth/update-failed', message: error.message || 'Failed to update credentials' };
  }
};

// Load users from localStorage on init
const loadUsers = () => {
  const storedUsers = localStorage.getItem('users');
  if (storedUsers) {
    try {
      users = JSON.parse(storedUsers);
    } catch (error) {
      console.error('Error parsing stored users:', error);
    }
  } else {
    // Save initial users to localStorage
    localStorage.setItem('users', JSON.stringify(users));
  }
};

// Export auth object for compatibility with existing code
export const auth = {
  currentUser: computed(() => authState.value.user)
};

// Initialize auth and load users on load
loadUsers();
initAuth();

<template>
  <Menu as="div" class="relative ml-3">
    <div>
      <MenuButton
        class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span class="sr-only">Open user menu</span>
        <div class="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
          <span class="text-indigo-600 font-medium">
            {{ authStore.user?.displayName?.[0]?.toUpperCase() || 'U' }}
          </span>
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <!-- Admin controls -->
        <template v-if="authStore.isAdmin">
          <MenuItem v-slot="{ active }">
            <RouterLink
              to="/settings"
              :class="[
                active ? 'bg-gray-100' : '',
                'block w-full px-4 py-2 text-left text-sm text-gray-700'
              ]"
            >
              Profile Settings
            </RouterLink>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100' : '',
                'block w-full px-4 py-2 text-left text-sm',
                editModeStore.isEditMode ? 'text-indigo-600' : 'text-gray-700'
              ]"
              @click="editModeStore.toggleEditMode"
            >
              {{ editModeStore.isEditMode ? 'Exit Edit Mode' : 'Edit Mode' }}
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100' : '',
                'block w-full px-4 py-2 text-left text-sm',
                maintenanceModeStore.isMaintenanceMode ? 'text-yellow-600' : 'text-gray-700'
              ]"
              @click="maintenanceModeStore.toggleMaintenanceMode"
            >
              {{ maintenanceModeStore.isMaintenanceMode ? 'Exit Maintenance' : 'Maintenance' }}
            </button>
          </MenuItem>
          <div class="border-t border-gray-200 my-1"></div>
        </template>

        <!-- User info -->
        <MenuItem v-slot="{ active }">
          <div
            :class="[
              active ? 'bg-gray-100' : '',
              'block px-4 py-2 text-sm text-gray-700'
            ]"
          >
            {{ authStore.user?.displayName || 'User' }}
          </div>
        </MenuItem>

        <!-- Logout -->
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-gray-100' : '',
              'block w-full px-4 py-2 text-left text-sm text-gray-700'
            ]"
            @click="handleLogout"
          >
            Sign out
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useAuthStore } from '../stores/auth'
import { useEditModeStore } from '../stores/editMode'
import { useMaintenanceModeStore } from '../stores/maintenanceMode'
import { useNotificationStore } from '../stores/notification'
import { useRouter, RouterLink } from 'vue-router'

const router = useRouter()
const authStore = useAuthStore()
const editModeStore = useEditModeStore()
const maintenanceModeStore = useMaintenanceModeStore()
const notificationStore = useNotificationStore()

const handleLogout = async () => {
  try {
    await authStore.logout()
    notificationStore.success('Successfully logged out')
    router.push('/login')
  } catch (err) {
    notificationStore.error('Error logging out')
    console.error('Logout error:', err)
  }
}
</script>

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEditModeStore = defineStore('editMode', () => {
  const isEditMode = ref(false);
  
  // Initialize edit mode from localStorage
  const initEditMode = () => {
    const storedEditMode = localStorage.getItem('edit_mode');
    if (storedEditMode !== null) {
      isEditMode.value = JSON.parse(storedEditMode);
    }
  };
  
  // Save edit mode to localStorage
  const saveEditMode = () => {
    localStorage.setItem('edit_mode', JSON.stringify(isEditMode.value));
  };
  
  // Toggle edit mode
  const toggleEditMode = () => {
    isEditMode.value = !isEditMode.value;
    saveEditMode();
  };
  
  // Set edit mode
  const setEditMode = (value) => {
    isEditMode.value = value;
    saveEditMode();
  };
  
  // Initialize on store creation
  initEditMode();
  
  return {
    isEditMode,
    toggleEditMode,
    setEditMode
  };
});

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref([]);
  
  // Add a new notification
  const notify = (message, type = 'info', duration = 3000) => {
    const id = Date.now();
    
    notifications.value.push({
      id,
      message,
      type,
      duration
    });
    
    // Auto-remove notification after duration
    if (duration > 0) {
      setTimeout(() => {
        removeNotification(id);
      }, duration);
    }
    
    return id;
  };
  
  // Success notification shorthand
  const success = (message, duration = 3000) => {
    return notify(message, 'success', duration);
  };
  
  // Error notification shorthand
  const error = (message, duration = 5000) => {
    return notify(message, 'error', duration);
  };
  
  // Info notification shorthand
  const info = (message, duration = 3000) => {
    return notify(message, 'info', duration);
  };
  
  // Warning notification shorthand
  const warning = (message, duration = 4000) => {
    return notify(message, 'warning', duration);
  };
  
  // Remove a notification by ID
  const removeNotification = (id) => {
    const index = notifications.value.findIndex(n => n.id === id);
    if (index !== -1) {
      notifications.value.splice(index, 1);
    }
  };
  
  // Clear all notifications
  const clearAll = () => {
    notifications.value = [];
  };
  
  return {
    notifications,
    notify,
    success,
    error,
    info,
    warning,
    removeNotification,
    clearAll
  };
});

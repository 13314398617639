<script setup>
import { ref, onMounted } from 'vue'
import { RouterView, RouterLink, useRouter } from 'vue-router'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from './stores/auth'
import { isSetupNeeded } from './services/localAuth'
import { useNotificationStore } from './stores/notification'
import { useMaintenanceModeStore } from './stores/maintenanceMode'
import { useEditModeStore } from './stores/editMode'
import NotificationToast from './components/NotificationToast.vue'
import UserMenu from './components/UserMenu.vue'

const isMenuOpen = ref(false)
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const maintenanceModeStore = useMaintenanceModeStore()
const editModeStore = useEditModeStore()
const router = useRouter()

const navigation = [
  { name: 'Home', path: '/' },
  { name: 'Professional', path: '/professional' },
  { name: 'Projects', path: '/projects' },
  { name: 'Blog', path: '/blog' },
  { name: 'Music', path: '/music' },
  { name: 'Cultural', path: '/cultural' },
  { name: 'Contact', path: '/contact' }
]

// Handle navigation in maintenance mode
const handleNavigation = (path) => {
  if (maintenanceModeStore.isMaintenanceMode && !authStore.isAdmin) {
    router.push('/login')
    return false
  }
  return true
}

// Initialize auth state and check for setup
onMounted(async () => {
  try {
    // Initialize auth store
    await authStore.init()
    console.log('Auth initialized, current user:', authStore.user)
    
    // Check if setup is needed
    if (isSetupNeeded() && router.currentRoute.value.name !== 'Setup') {
      router.push('/setup')
    }
  } catch (err) {
    console.error('Error initializing auth:', err)
    notificationStore.error('Error initializing authentication')
  }
})

// Logout handler
const handleLogout = async () => {
  try {
    await authStore.logout()
    notificationStore.success('Successfully logged out')
    router.push('/login')
  } catch (err) {
    notificationStore.error('Error logging out')
    console.error('Logout error:', err)
  }
}

console.log('App.vue setup running')
</script>

<template>
  <div class="min-h-screen bg-gray-50 flex flex-col">

    <!-- Navigation -->
    <nav class="bg-white shadow-sm sticky top-0 z-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <RouterLink to="/" class="text-xl font-bold text-gray-900 hover:text-indigo-600 transition-colors">
                MRV
              </RouterLink>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <RouterLink
                v-for="item in navigation"
                :key="item.name"
                v-slot="{ navigate }"
                :to="item.path"
                custom
              >
                <a
                  :class="[
                    'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-indigo-600 transition-colors',
                    $route.path === item.path ? 'text-indigo-600 border-b-2 border-indigo-600' : ''
                  ]"
                  href="#"
                  @click="(e) => {
                    e.preventDefault();
                    if (handleNavigation(item.path)) {
                      navigate();
                    }
                  }"
                >
                  {{ item.name }}
                </a>
              </RouterLink>
            </div>
          </div>
          
          <!-- User menu -->
          <div class="hidden sm:flex sm:items-center sm:ml-6">
            <template v-if="authStore.isAuthenticated">
              <UserMenu />
            </template>
            <template v-else>
              <RouterLink
                to="/login"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-indigo-600 transition-colors"
              >
                Login
              </RouterLink>
            </template>
          </div>

          <!-- Mobile menu button -->
          <div class="-mr-2 flex items-center sm:hidden">
            <button
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 transition-colors"
              @click="isMenuOpen = !isMenuOpen"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!isMenuOpen" class="block h-6 w-6" />
              <XMarkIcon v-else class="block h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile menu -->
      <div v-if="isMenuOpen" class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <RouterLink
            v-for="item in navigation"
            :key="item.name"
            v-slot="{ navigate }"
            :to="item.path"
            custom
          >
            <a
              :class="[
                'block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50 transition-colors',
                $route.path === item.path ? 'text-indigo-600 bg-indigo-50 border-l-4 border-indigo-600' : ''
              ]"
              href="#"
              @click="(e) => {
                e.preventDefault();
                if (handleNavigation(item.path)) {
                  navigate();
                  isMenuOpen = false;
                }
              }"
            >
              {{ item.name }}
            </a>
          </RouterLink>

          <!-- Mobile menu items -->
          <template v-if="authStore.isAuthenticated">
            <!-- User info -->
            <div class="block px-4 py-2 text-base font-medium text-gray-700 border-b border-gray-200">
              {{ authStore.user?.displayName || 'User' }}
            </div>

            <!-- Admin controls -->
            <template v-if="authStore.isAdmin">
              <button
                class="block w-full text-left px-4 py-2 text-base font-medium hover:bg-gray-50 transition-colors"
                :class="{
                  'text-indigo-600': editModeStore.isEditMode,
                  'text-gray-700': !editModeStore.isEditMode
                }"
                @click="editModeStore.toggleEditMode"
              >
                {{ editModeStore.isEditMode ? 'Exit Edit Mode' : 'Edit Mode' }}
              </button>

              <button
                class="block w-full text-left px-4 py-2 text-base font-medium hover:bg-gray-50 transition-colors"
                :class="{
                  'text-yellow-600': maintenanceStore.isMaintenanceMode,
                  'text-gray-700': !maintenanceStore.isMaintenanceMode
                }"
                @click="maintenanceStore.toggleMaintenanceMode"
              >
                {{ maintenanceStore.isMaintenanceMode ? 'Exit Maintenance' : 'Maintenance' }}
              </button>

              <div class="border-t border-gray-200"></div>
            </template>

            <!-- Logout -->
            <button
              class="block w-full text-left px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 transition-colors"
              @click="handleLogout"
            >
              Sign out
            </button>
          </template>
          <template v-else>
            <RouterLink
              to="/login"
              class="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 transition-colors"
              @click="isMenuOpen = false"
            >
              Login
            </RouterLink>
          </template>
        </div>
      </div>
    </nav>

    <!-- Main content -->
    <main class="flex-grow">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <RouterView />
      </div>
    </main>

    <!-- Footer -->
    <footer class="bg-white shadow-sm mt-auto py-4">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p class="text-center text-sm text-gray-500">
          © {{ new Date().getFullYear() }} Manuel Rodríguez de Viguri. All rights reserved.
        </p>
      </div>
    </footer>
    <!-- Notifications -->
    <NotificationToast />
  </div>
</template>

<style>
/* Page Transition Animations */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.2s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>

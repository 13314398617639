<template>
  <div class="relative" :class="{ 'editable-content': isEditMode }">
    <!-- View mode -->
    <div v-if="!isEditing" :class="{ 'cursor-pointer hover:bg-gray-50 hover:ring-2 hover:ring-indigo-200 p-2 rounded': isEditMode }" @click="startEditing">
      <div ref="contentElement" :data-content-id="contentId">
        <slot v-if="!currentContent"></slot>
        <template v-else>
          <div v-if="contentType === 'html'" class="content-view" v-html="currentContent"></div>
          <pre v-else-if="contentType === 'json'">{{ formatJson(currentContent) }}</pre>
          <div v-else>{{ currentContent }}</div>
        </template>
      </div>
      
      <!-- Edit button (only visible in edit mode) -->
      <button 
        v-if="isEditMode" 
        class="absolute top-2 right-2 p-1 bg-indigo-100 text-indigo-700 rounded-full hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click.stop="startEditing"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </button>
    </div>
    
    <!-- Edit mode -->
    <div v-else class="p-2 border-2 border-indigo-300 rounded-md bg-white shadow-sm">
      <!-- Rich text editor for HTML content -->
      <div v-if="contentType === 'html'" class="mb-4">
        <!-- Toolbar -->
        <div class="flex flex-wrap gap-1 p-2 mb-2 bg-gray-50 border border-gray-300 rounded-t-md">
          <!-- Text style -->
          <button 
            v-for="style in textStyles" 
            :key="style.command"
            :title="style.label"
            class="p-1.5 hover:bg-gray-200 rounded transition-colors"
            :class="{ 'bg-gray-200': isCommandActive(style.command) }"
            @click="execCommand(style.command)"
          >
            <span v-html="style.icon"></span>
          </button>

          <!-- Separator -->
          <div class="w-px h-6 bg-gray-300 mx-1"></div>

          <!-- Headings -->
          <select 
            class="px-2 py-1 bg-white border border-gray-300 rounded text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            @change="execCommand('formatBlock', $event.target.value)"
          >
            <option value="p">Normal</option>
            <option value="h1">Heading 1</option>
            <option value="h2">Heading 2</option>
            <option value="h3">Heading 3</option>
          </select>

          <!-- Separator -->
          <div class="w-px h-6 bg-gray-300 mx-1"></div>

          <!-- Text alignment -->
          <button 
            v-for="align in alignments" 
            :key="align.command"
            :title="align.label"
            class="p-1.5 hover:bg-gray-200 rounded transition-colors"
            :class="{ 'bg-gray-200': isCommandActive(align.command) }"
            @click="execCommand(align.command)"
          >
            <span v-html="align.icon"></span>
          </button>

          <!-- Separator -->
          <div class="w-px h-6 bg-gray-300 mx-1"></div>

          <!-- Lists -->
          <button 
            v-for="list in lists" 
            :key="list.command"
            :title="list.label"
            class="p-1.5 hover:bg-gray-200 rounded transition-colors"
            :class="{ 'bg-gray-200': isCommandActive(list.command) }"
            @click="execCommand(list.command)"
          >
            <span v-html="list.icon"></span>
          </button>
        </div>

        <!-- View mode -->
        <div v-if="!isEditing" class="border border-gray-300 border-t-0 rounded-b-md p-2 min-h-[100px]">
          <div class="content-view" v-html="currentContent"></div>
        </div>
        
        <!-- Edit mode -->
        <div 
          v-else
          ref="htmlEditor" 
          class="border border-gray-300 border-t-0 rounded-b-md p-2 min-h-[100px] focus:outline-none focus:ring-2 focus:ring-indigo-500"
          contenteditable="true"
          @input="handleInput"
          @paste="handlePaste"
          @keydown="handleKeyDown"
          @focus="handleFocus"
          @blur="handleBlur"
        ></div>
      </div>
      
      <!-- Text area for plain text -->
      <textarea 
        v-else-if="contentType === 'text'"
        v-model="editedContent"
        class="w-full border border-gray-300 rounded-md p-2 min-h-[100px] focus:outline-none focus:ring-2 focus:ring-indigo-500"
        :rows="rows"
        @input="handleTextInput"
      ></textarea>
      
      <!-- JSON editor for structured content -->
      <div v-else-if="contentType === 'json'" class="mb-4">
        <textarea 
          v-model="editedContent"
          class="w-full font-mono text-sm border border-gray-300 rounded-md p-2 min-h-[200px] focus:outline-none focus:ring-2 focus:ring-indigo-500"
          :rows="rows"
          @input="handleJsonInput"
        ></textarea>
        <p v-if="jsonError" class="mt-1 text-sm text-red-600">{{ jsonError }}</p>
      </div>
      
      <!-- Action buttons -->
      <div class="flex justify-end space-x-2 mt-2">
        <button 
          class="px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-700 hover:bg-gray-50" 
          @click="cancelEditing"
        >
          Cancel
        </button>
        <button 
          class="px-3 py-1 bg-indigo-600 border border-transparent rounded-md text-sm text-white hover:bg-indigo-700" 
          :disabled="isSaving"
          @click="saveContent"
        >
          {{ isSaving ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from 'vue-router';
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue';
import { useEditModeStore } from '../stores/editMode';
import { useContentStore } from '../stores/content';

const props = defineProps({
  contentId: {
    type: String,
    required: true
  },
  viewName: {
    type: String,
    required: true
  },
  contentType: {
    type: String,
    default: 'text',
    validator: (value) => ['text', 'html', 'json'].includes(value)
  },
  rows: {
    type: Number,
    default: 5
  }
});

const emit = defineEmits(['update']);

const editModeStore = useEditModeStore();
const contentStore = useContentStore();

const isEditing = ref(false);
const editedContent = ref('');
const originalContent = ref('');
const currentContent = ref(null);
const isSaving = ref(false);
const jsonError = ref('');
const htmlEditor = ref(null);
const contentElement = ref(null);

// Computed property for edit mode state
const isEditMode = computed(() => editModeStore.isEditMode);

// Text styling options
const textStyles = [
  {
    command: 'bold',
    label: 'Bold',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M12.8 11.6c.6-.8 1-1.8 1-2.8 0-2.8-2.2-5-5-5H4v12h5.8c2.8 0 5-2.2 5-5 0-1-.4-2-1.2-2.8zM7 6h1.8c1.1 0 2 .9 2 2s-.9 2-2 2H7V6zm2 8H7v-2h2c1.1 0 2 .9 2 2s-.9 2-2 2z"/></svg>'
  },
  {
    command: 'italic',
    label: 'Italic',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M14 4v2h-2.6l-3.7 8H10v2H6v-2h2.6l3.7-8H10V4h4z"/></svg>'
  },
  {
    command: 'underline',
    label: 'Underline',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M10 15.6c2.9 0 5.2-2.3 5.2-5.2V4h-2.4v6.4c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.8-1.2-2.8-2.8V4H4.8v6.4c0 2.9 2.3 5.2 5.2 5.2zM16 17H4v-2h12v2z"/></svg>'
  }
];

// Text alignment options
const alignments = [
  {
    command: 'justifyLeft',
    label: 'Align Left',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M3 5h14v2H3V5zm0 4h8v2H3V9zm0 4h14v2H3v-2z"/></svg>'
  },
  {
    command: 'justifyCenter',
    label: 'Center',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M3 5h14v2H3V5zm3 4h8v2H6V9zm-3 4h14v2H3v-2z"/></svg>'
  },
  {
    command: 'justifyRight',
    label: 'Align Right',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M3 5h14v2H3V5zm6 4h8v2H9V9zm-6 4h14v2H3v-2z"/></svg>'
  }
];

// List options
const lists = [
  {
    command: 'insertUnorderedList',
    label: 'Bullet List',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M4 6h2v2H4V6zm0 5h2v2H4v-2zm0 5h2v2H4v-2zm4-10h12v2H8V6zm0 5h12v2H8v-2zm0 5h12v2H8v-2z"/></svg>'
  },
  {
    command: 'insertOrderedList',
    label: 'Numbered List',
    icon: '<svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path d="M3 6h2v2H3V6zm0 5h2v2H3v-2zm0 5h2v2H3v-2zm4-10h12v2H7V6zm0 5h12v2H7v-2zm0 5h12v2H7v-2z"/></svg>'
  }
];



// Format JSON content for display
const formatJson = (content) => {
  try {
    return typeof content === 'string' 
      ? content 
      : JSON.stringify(content, null, 2);
  } catch (err) {
    console.error('Error formatting JSON:', err);
    return String(content);
  }
};

// Execute rich text command
const execCommand = (command, value = null) => {
  if (htmlEditor.value) {
    // Focus the editor first
    htmlEditor.value.focus();
    
    // Execute the command
    document.execCommand(command, false, value);
    
    // Update content
    editedContent.value = htmlEditor.value.innerHTML;
    
    // Log the command execution
    console.log(`Executed command ${command}:`, {
      value,
      content: editedContent.value
    });
  }
};

// Check if command is active
const isCommandActive = (command) => {
  try {
    return document.queryCommandState(command);
  } catch (err) {
    console.warn(`Error checking command state for ${command}:`, err);
    return false;
  }
};

// Debounce function implementation
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Debounced content update
const updateContent = debounce(() => {
  if (htmlEditor.value) {
    editedContent.value = htmlEditor.value.innerHTML;
  }
}, 100);

// Handle input events
const handleInput = (event) => {
  // We don't need to do anything here as the MutationObserver will handle content updates
  // This prevents cursor jumping by not manipulating the DOM directly
  console.log('Input event handled by MutationObserver');
};

// Handle paste events to preserve formatting
const handlePaste = (e) => {
  e.preventDefault();
  const text = e.clipboardData.getData('text/html') || e.clipboardData.getData('text/plain');
  document.execCommand('insertHTML', false, text);
};

// Handle special keys
const handleKeyDown = (e) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    document.execCommand('insertParagraph', false);
    e.preventDefault();
  }
};

// Handle text content updates
const handleTextInput = (event) => {
  editedContent.value = event.target.value;
};

// Handle JSON content updates
const handleJsonInput = (event) => {
  editedContent.value = event.target.value;
  try {
    JSON.parse(event.target.value);
    jsonError.value = '';
  } catch (err) {
    jsonError.value = 'Invalid JSON: ' + err.message;
  }
};

// Initialize content when component mounts
onMounted(async () => {
  try {
    console.log(`Initializing content for ${props.viewName}.${props.contentId}`);
    // Load content from the store
    const viewContent = await contentStore.getContent(props.viewName);
    console.log(`Retrieved content for view ${props.viewName}:`, viewContent);
    const elementContent = viewContent[props.contentId];
    console.log(`Content for ${props.contentId}:`, elementContent);
    
    // Get initial content from store or slot
    if (elementContent !== undefined && elementContent !== '') {
      // Use stored content
      console.log(`Using stored content for ${props.contentId}`);
      currentContent.value = elementContent;
      originalContent.value = elementContent;
      editedContent.value = elementContent;
    } else if (contentElement.value) {
      // If no stored content, use slot content as initial content
      console.log(`No stored content, checking slot content for ${props.contentId}`);
      console.log('Content element:', contentElement.value);
      const slotContent = contentElement.value.innerHTML;
      console.log(`Slot content for ${props.contentId}:`, slotContent);
      if (slotContent) {
        // For HTML content, preserve the HTML structure
        if (props.contentType === 'html') {
          console.log(`Using HTML slot content for ${props.contentId}`);
          currentContent.value = slotContent;
          originalContent.value = slotContent;
          editedContent.value = slotContent;
          
          // Save the initial content to the store
          console.log(`Saving slot content to store for ${props.viewName}.${props.contentId}`);
          await contentStore.saveContent(props.viewName, {
            [props.contentId]: slotContent
          });
        } else {
          // For other types, use text content
          const textContent = contentElement.value.textContent.trim();
          currentContent.value = textContent;
          originalContent.value = textContent;
          editedContent.value = textContent;
          
          // Save the initial content to the store
          await contentStore.saveContent(props.viewName, {
            [props.contentId]: textContent
          });
        }
      }
    }
    
    console.log(`Initialized content for ${props.viewName}.${props.contentId}:`, {
      type: props.contentType,
      stored: elementContent,
      current: currentContent.value,
      original: originalContent.value,
      edited: editedContent.value,
      element: contentElement.value?.innerHTML
    });
  } catch (err) {
    console.error(`Error initializing content for ${props.viewName}.${props.contentId}:`, err);
  }
});

// Handle focus and blur events
const handleFocus = () => {
  if (htmlEditor.value) {
    // Ensure the editor is editable
    htmlEditor.value.contentEditable = 'true';
  }
};

const handleBlur = () => {
  if (htmlEditor.value) {
    // Update content on blur
    editedContent.value = htmlEditor.value.innerHTML;
  }
};

// Watch for content store changes
watch(
  () => contentStore.content[props.viewName]?.[props.contentId],
  (newContent) => {
    if (newContent !== undefined && !isEditing.value) {
      currentContent.value = newContent;
      originalContent.value = newContent;
    }
  }
);

// Setup MutationObserver to track content changes without disrupting cursor
let observer = null;

const setupMutationObserver = () => {
  if (!htmlEditor.value) return;
  
  // Disconnect any existing observer
  if (observer) {
    observer.disconnect();
  }
  
  // Create a new observer
  observer = new MutationObserver((mutations) => {
    // Only update the content value, don't modify the DOM
    editedContent.value = htmlEditor.value.innerHTML;
  });
  
  // Start observing
  observer.observe(htmlEditor.value, {
    childList: true,
    attributes: true,
    characterData: true,
    subtree: true
  });
  
  console.log('MutationObserver setup for HTML editor');
};

// Start editing
const startEditing = async () => {
  if (!isEditMode.value) return;
  console.log('Starting edit mode...');
  
  isEditing.value = true;
  editedContent.value = currentContent.value || '';
  await nextTick();
  
  // Initialize editor with content based on type
  if (props.contentType === 'html') {
    nextTick(() => {
      if (htmlEditor.value) {
        // Set content and focus
        htmlEditor.value.innerHTML = editedContent.value;
        htmlEditor.value.focus();
        
        // Setup MutationObserver to track changes
        setupMutationObserver();
        
        // Move cursor to end
        const range = document.createRange();
        range.selectNodeContents(htmlEditor.value);
        range.collapse(false);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
  } else if (props.contentType === 'json') {
    try {
      // If it's already a JSON string, use it directly
      if (typeof currentContent.value === 'string' && currentContent.value.trim()) {
        // Try to parse and re-stringify to validate and format
        const parsed = JSON.parse(currentContent.value);
        editedContent.value = JSON.stringify(parsed, null, 2);
      } else {
        // Default to empty object
        editedContent.value = '{}';
      }
      jsonError.value = '';
    } catch (err) {
      console.error('Error parsing JSON:', err);
      // Keep the original content even if invalid
      editedContent.value = currentContent.value || '{}';
      jsonError.value = 'Invalid JSON: ' + err.message;
    }
  } else {
    // For plain text
    editedContent.value = currentContent.value;
  }
  
  // Store original content for reverting
  originalContent.value = editedContent.value;
  
  // Log the content being edited
  console.log('Starting edit with content:', {
    type: props.contentType,
    content: editedContent.value,
    original: originalContent.value,
    current: currentContent.value
  });
};

// Cancel editing
const cancelEditing = () => {
  // Disconnect the observer if it exists
  if (observer) {
    observer.disconnect();
    observer = null;
  }
  
  isEditing.value = false;
  editedContent.value = originalContent.value;
  
  // Reset HTML editor if needed
  if (props.contentType === 'html' && htmlEditor.value) {
    htmlEditor.value.innerHTML = originalContent.value;
  }
  
  // Reset to original content
  currentContent.value = originalContent.value;
  jsonError.value = '';
};

// Save content
const saveContent = async () => {
  isSaving.value = true;
  jsonError.value = '';
  
  try {
    let contentToSave;
    
    // For HTML content, get the innerHTML from the contenteditable div
    if (props.contentType === 'html' && htmlEditor.value) {
      // Disconnect the observer before saving
      if (observer) {
        observer.disconnect();
      }
      contentToSave = htmlEditor.value.innerHTML;
      console.log('Saving HTML content:', contentToSave);
    } 
    // For JSON content, validate and parse it
    else if (props.contentType === 'json') {
      try {
        contentToSave = JSON.parse(editedContent.value);
        console.log('Saving JSON content:', contentToSave);
      } catch (err) {
        console.error('Invalid JSON:', err);
        jsonError.value = 'Invalid JSON: ' + err.message;
        isSaving.value = false;
        return;
      }
    } 
    // For text content, just use the edited content
    else {
      contentToSave = editedContent.value;
      console.log('Saving text content:', contentToSave);
    }
    
    // Save content using the content store
    console.log(`Saving content for ${props.viewName}.${props.contentId}:`, contentToSave);
    const success = await contentStore.saveContent(props.viewName, {
      [props.contentId]: contentToSave
    });
    
    if (!success) {
      throw new Error('Failed to save content');
    }
    
    // Update the current content
    currentContent.value = contentToSave;
    originalContent.value = props.contentType === 'json' 
      ? JSON.stringify(contentToSave, null, 2)
      : String(contentToSave);
    
    // Emit update event with the new content
    emit('update', contentToSave);
    
    // Exit edit mode
    isEditing.value = false;
  } catch (err) {
    console.error('Error saving content:', err);
  } finally {
    isSaving.value = false;
  }
};

// Exit edit mode when global edit mode is turned off
watch(() => editModeStore.isEditMode, (newValue) => {
  if (!newValue && isEditing.value) {
    cancelEditing();
  }
});

// Expose methods for testing
defineExpose({
  startEditing,
  cancelEditing,
  saveContent,
  isEditing
});

// Clean up resources when component is unmounted
onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<style scoped>
.editable-content {
  position: relative;
}

.editable-content:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed #6366f1;
  pointer-events: none;
  z-index: 1;
}
</style>
